import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const {authUser} = useSelector(state=> state.auth);
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={authUser?.image?.url ?? 'https://via.placeholder.com/150x150'} className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">{authUser.name}</span>
      </Popover>
    </div>

  )
};

export default UserProfile;
