// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const UPDATE_LOADER = 'UPDATE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'USER_DATA';
export const USER_LIST_DATA = 'USER_LIST_DATA';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const UPDATE_LOAD_USER = 'UPDATE_LOAD_USER';


//Building Data
export const BUILDING_DATA = 'BUILDING_DATA';
export const ADD_BUILDING = 'ADD_BUILDING';

//Apartment Data
export const APARTMENT_DATA = 'APARTMENT_DATA';
export const APARTMENT_DETAILS = 'APARTMENT_DETAILS';
export const APARTMENT_PAYMENT = 'APARTMENT_PAYMENT';
export const APARTMENT_WARNINGS = 'APARTMENT_WARNINGS';
export const APARTMENT_PENALTIES = 'APARTMENT_PENALTIES';
export const APARTMENT_ACCESS_CONTROL = 'APARTMENT_ACCESS_CONTROL';
export const APARTMENT_DATA_WITHOUT_TENANTS = 'APARTMENT_DATA_WITHOUT_TENANTS';


//Visitor
export const VISITOR_DATA = 'VISITOR_DATA';

//Voucher
export const VOUCHER_DATA = 'VOUCHER_DATA';

//Services
export const SERVICE_DATA = 'SERVICE_DATA';
export const SERVICE_DATA_HAS_CODE = 'SERVICE_DATA_HAS_CODE';
export const SERVICE_TYPE_TENANT_TYPE_MAP = 'SERVICE_TYPE_TENANT_TYPE_MAP';
export const SERVICE_TYPE_BUILDING_MAP = 'SERVICE_TYPE_BUILDING_MAP';

//Mobile App Settings
export const MENU_DATA = 'MENU_DATA';

//Mobile App Settings
export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const DASHBOARD_PAYMENT_HISTORY = 'DASHBOARD_PAYMENT_HISTORY';
export const DASHBOARD_PORTAL_STATUS = 'DASHBOARD_PORTAL_STATUS';


//REPORT_DATA App Settings
export const REPORT_DATA = 'REPORT_DATA';

//Access control
export const DEPARTMENTS_DATA = 'DEPARTMENTS_DATA';
export const ACC_LEVELS_DATA = 'ACC_LEVELS_DATA';
export const DEFAULT_ACCESS_LEVEL = 'DEFAULT_ACCESS_LEVEL';


//Staff
export const STAFFS_DATA = 'STAFFS_DATA';

//Retail
export const RETAILS_DATA = 'RETAILS_DATA';

//Tenants
export const BLACKLISTED_TENANTS_DATA = 'BLACKLISTED_TENANTS_DATA';
export const TENANTS_DATA = 'TENANTS_DATA';

//Payment Account
export const PAYMENT_ACCOUNT_DATA = 'PAYMENT_ACCOUNT_DATA';

// Laundry
export const LAUNDRY_API_DATA = 'LAUNDRY_API_DATA';
export const LAUNDRY_SITE_DATA = 'LAUNDRY_SITE_DATA';
export const LAUNDRY_MI_FARE_SITE_DATA = 'LAUNDRY_MI_FARE_SITE_DATA';


//Marketing
export const MARKETING_DASHBOARD_DATA = 'MARKETING_DASHBOARD_DATA';
export const MARKETING_MEDIA_DATA = 'MARKETING_MEDIA_DATA';
export const MARKETING_ADMINS_DATA = 'MARKETING_ADMINS_DATA';


// Maintenance
export const TASK_LIST_DATA = 'TASK_LIST_DATA';
export const BOARD_DATA = 'BOARD_DATA';
export const ASSIGNEE_DATA = 'ASSIGNEE_DATA';
export const MAINTENANCE_USERS_DATA = 'MAINTENANCE_USERS_DATA';
export const MAINTENANCE_DEPARTMENT_DATA = 'MAINTENANCE_DEPARTMENT_DATA';
export const MAINTENANCE_DEPARTMENT_STAFFS = 'MAINTENANCE_DEPARTMENT_STAFFS';
export const MAINTENANCE_APPLIED_FILTER = 'MAINTENANCE_APPLIED_FILTER';

// Notice Board
export const NOTICE_BOARD_DATA = 'NOTICE_BOARD_DATA';
