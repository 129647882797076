export const UserRole = Object.freeze({
    ROOT: 'root',
    SUPER_ADMIN: 'super-admin',
    ADMIN: 'admin',
    STAFF: 'staff',
    MARKETING: 'marketing',
    MAINTENANCE_ADMIN: 'maintenance-admin',
    MAINTENANCE_DEPT_ADMIN: 'maintenance-dept-head',
    MAINTENANCE_STAFF: 'maintenance-staff',
});

export const UserRoleLableMap ={
    'super-admin': 'Super Admin',
    'admin': 'Admin',
    'staff': 'Staff',
    'marketing': 'Marketing',
    'maintenance-admin': 'Admin',
    'maintenance-dept-head': 'Dept. Head',
    'maintenance-staff': 'Staff'
}