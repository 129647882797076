import { LAUNDRY_API_DATA, LAUNDRY_MI_FARE_SITE_DATA, LAUNDRY_SITE_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    laundrySites: [],
    miFareSites: [],
    laundryAPIS: [],
    isLoadingMiFareSite: true,
};

const laundrySettingsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LAUNDRY_MI_FARE_SITE_DATA: {
            return {
                ...state,
                isLoadingMiFareSite: false,
                miFareSites: action.payload,
            }
        }
        case LAUNDRY_SITE_DATA: {
            return {
                ...state,
                laundrySites: action.payload,
            }
        }
        case LAUNDRY_API_DATA: {
            return {
                ...state,
                laundryAPIS: action.payload,
            }
        }
        default:
            return state;
    }
}

export default laundrySettingsReducer;