import {
  ASSIGNEE_DATA,
  BOARD_DATA, MAINTENANCE_APPLIED_FILTER,
  MAINTENANCE_DEPARTMENT_DATA,
  MAINTENANCE_DEPARTMENT_STAFFS,
  MAINTENANCE_TASK_UPDATED,
  TASK_LIST_DATA
} from "../../constants/ActionTypes";
import moment from "moment/moment";
import {ResponseType} from "../../enums/ResponseType";

export const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const INIT_STATE = {
  boardData: [],
  taskList: {},
  assignees: [],
  users: [],
  maintenanceDepartments: [],
  staffs: [],
  appliedFilters: {responseType: ResponseType.KANBAN, startDate: moment().add(-60, 'd').startOf('day').format(dateFormat), endDate: moment().endOf('day').format(dateFormat), assignedTos: [], page: 1, perPage: 10, status: null},
};

const maintenanceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BOARD_DATA: {
      return {
        ...state,
        boardData: action.payload,
      }
    }
    case TASK_LIST_DATA: {
      return {
        ...state,
        taskList: action.payload,
      }
    }
    case ASSIGNEE_DATA: {
      return {
        ...state,
        assignees: action.payload,
      }
    }
    case MAINTENANCE_DEPARTMENT_DATA: {
      return {
        ...state,
        maintenanceDepartments: action.payload,
      }
    }
    case MAINTENANCE_DEPARTMENT_STAFFS: {
      return {
        ...state,
        staffs: action.payload,
      }
    }
    case MAINTENANCE_APPLIED_FILTER: {
      const startDate = action.payload.startDate ? moment(action.payload.startDate).startOf('day').format(dateFormat) : null;
      const endDate = action.payload.endDate ? moment(action.payload.endDate).endOf('day').format(dateFormat) : null;
      // if not null then no need to update
      if(startDate) {
        action.payload.startDate = startDate;
      }
      if(endDate) {
        action.payload.endDate = endDate;
      }
      const appliedFilters = {...state.appliedFilters, ...action.payload};
      return {
        ...state,
        appliedFilters: appliedFilters,
      }
    }
    default:
      return state;
  }
}

export default maintenanceReducer;
