import { DASHBOARD_DATA, DASHBOARD_PAYMENT_HISTORY, DASHBOARD_PORTAL_STATUS } from "../../constants/ActionTypes";

const INIT_STATE = {
    dashboardData: null,
    paymentHistory: [],
    portalStatus: null
};

const dashboardReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_DATA: {
            return {
                ...state,
                dashboardData: action.payload,
            }
        }
        case DASHBOARD_PAYMENT_HISTORY: {
            return {
                ...state,
                paymentHistory: action.payload,
            }
        }
        case DASHBOARD_PORTAL_STATUS: {
            return {
                ...state,
                portalStatus: action.payload,
            }
        }
        default:
            return state;
    }
}

export default dashboardReducer;
