import { LAUNDRY_SITE_DATA, MENU_DATA, PAYMENT_ACCOUNT_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    menus: [],
    paymentAccounts: [],
    laundrySites: []
};

const mobileAppSettingsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MENU_DATA: {
            return {
                ...state,
                menus: action.payload,
            }
        }
        case PAYMENT_ACCOUNT_DATA: {
            return {
                ...state,
                paymentAccounts: action.payload,
            }
        }
        case LAUNDRY_SITE_DATA: {
            return {
                ...state,
                laundrySites: action.payload,
            }
        }
        default:
            return state;
    }
}

export default mobileAppSettingsReducer;