import {
  ASSIGNEE_DATA,
  BOARD_DATA,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS, MAINTENANCE_APPLIED_FILTER, MAINTENANCE_DEPARTMENT_DATA, MAINTENANCE_DEPARTMENT_STAFFS,
  SIGNOUT_USER_SUCCESS, TASK_LIST_DATA
} from "../../constants/ActionTypes";
import axios from 'util/Api';
import {ResponseType} from "../../enums/ResponseType";

export const updateAppliedFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: MAINTENANCE_APPLIED_FILTER, payload: filter });
  }
};

export const fetchBoardData = () => {
  return (dispatch, getState) => {
    const { appliedFilters } = getState().maintenanceReducer;
    dispatch({ type: FETCH_START });
    axios.get('/maintenance-faults', {params: appliedFilters}).then(({ data }) => {
      if (data.success) {
        dispatch({ type: appliedFilters.responseType === ResponseType.KANBAN ? BOARD_DATA : TASK_LIST_DATA, payload: data.data });
        dispatch({ type: FETCH_SUCCESS });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      if (error.response.status === 401) {
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      }
      else{
        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
    });
}};

// export const updateMaintenanceFault = (faultId, requestBody) => {
//   return (dispatch) => {
//     axios.put(`/maintenance-faults/${faultId}`, requestBody).then(({ data }) => {
//       if (data.success) {
//         dispatch(fetchBoardData());
//       } else {
//         dispatch({ type: FETCH_ERROR, payload: data.msg });
//         dispatch({ type: MAINTENANCE_TASK_UPDATED, payload: false });
//       }
//     }).catch(function (error) {
//       if (error.response.status === 401) {
//         dispatch({ type: SIGNOUT_USER_SUCCESS });
//       }
//       else{
//         dispatch({ type: FETCH_ERROR, payload: error.message });
//       }
//     });
// }};

export const updateMaintenanceFault = (faultId, requestBody) => {
  return axios.put(`/maintenance-faults/${faultId}`, requestBody).then(({ data }) => data);
};

// update assignee
// export const updateAssignee = (fault_id, user_id = null) => {
//   return (dispatch) => {
//     axios.post(`/maintenance-faults/assignment`, { fault_id, user_id }).then(({ data }) => {
//       if (data.success) {
//         dispatch(fetchBoardData());
//       } else {
//         dispatch({ type: FETCH_ERROR, payload: data.error });
//         dispatch({ type: MAINTENANCE_TASK_UPDATED, payload: false });
//       }
//     }).catch(function (error) {
//       if (error.response.status === 401) {
//         dispatch({ type: SIGNOUT_USER_SUCCESS });
//       }
//       else{
//         dispatch({ type: FETCH_ERROR, payload: error.message });
//       }
//     });
// }};

// update assignee to return promise response
export const updateAssignee = (fault_id, user_id = null) => {
  return axios.post(`/maintenance-faults/assignment`, { fault_id, user_id }).then(({ data }) => data);
};

// get all staff users
export const fetchMaintenanceUsers = (roles = null) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(`/maintenance-users`, {roles}).then(({ data }) => {
      if (data.success) {
        dispatch({ type: ASSIGNEE_DATA, payload: data.data });
        dispatch({ type: FETCH_SUCCESS });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      if (error.response.status === 401) {
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      }
      else{
        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
    });
}};

export const fetchMaintenanceDepartments = (withFaultsCount = false) => {

  return (dispatch) => {
    axios.get(`/maintenance-departments?withFaultsCount=${withFaultsCount}`).then(({ data }) => {
      if (data.success) {
        dispatch({ type: MAINTENANCE_DEPARTMENT_DATA, payload: data.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      if (error.response.status === 401) {
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      }
      else{
        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
    });
}};

export const fetchMaintenanceDepartmentStaffs = () => {
  return (dispatch) => {
    axios.get(`/maintenance-departments/staffs`).then(({ data }) => {
      if (data.success) {
        dispatch({ type: MAINTENANCE_DEPARTMENT_STAFFS, payload: data.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      if (error.response.status === 401) {
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      }
      else{
        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
    });
}};
