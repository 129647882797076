import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    DASHBOARD_DATA,
    DASHBOARD_PAYMENT_HISTORY,
    SIGNOUT_USER_SUCCESS,
    DASHBOARD_PORTAL_STATUS,
} from "../../constants/ActionTypes";
import axios from 'util/Api';

export const fetchDashboardData = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get(`dashboard`).then(({ data }) => {
            console.log("data: ", data.data);
            if (data.success) {
                dispatch({ type: DASHBOARD_DATA, payload: data.data });
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.error });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                dispatch({ type: SIGNOUT_USER_SUCCESS });
            }
            else{
                dispatch({ type: FETCH_ERROR, payload: error.message });
            }
            console.log("Error****:", error.message);
        });
    }
}

export const fetchDashboardPaymentHistory = (limit) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get(`dashboard/paymentHistory/${limit}`).then(({ data }) => {
            console.log("data: ", data.data);
            if (data.success) {
                dispatch({ type: DASHBOARD_PAYMENT_HISTORY, payload: data.data });
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.error });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                dispatch({ type: SIGNOUT_USER_SUCCESS });
            }
            else{
                dispatch({ type: FETCH_ERROR, payload: error.message });
            }
            console.log("Error****:", error.message);
        });
    }
}

export const fetchPortalStatus = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get(`dashboard/portalsStatus`).then(({ data }) => {
            console.log("data: ", data.data);
            if (data.success) {
                dispatch({ type: DASHBOARD_PORTAL_STATUS, payload: data.data });
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: data.error });
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                dispatch({ type: SIGNOUT_USER_SUCCESS });
            }
            else{
                dispatch({ type: FETCH_ERROR, payload: error.message });
            }
            console.log("Error****:", error.message);
        });
    }
}