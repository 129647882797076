import { NOTICE_BOARD_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    notices: [],
};


const noticeBoardReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NOTICE_BOARD_DATA: {
            return {
                ...state,
                notices: action.payload,
            }
        }
        default:
            return state;
    }
}

export default noticeBoardReducer;
