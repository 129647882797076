import React, { useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import { LogoutOutlined } from "@ant-design/icons";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import { userSignOut } from "../../appRedux/actions/Auth";
import { UserRole } from "../../enums/UserRole";
import {
  hasAdminAccess,
  hasAllAccess,
  hasMaintenanceAccess,
  hasMaintenanceAdminAccess,
  hasMarketingAccess,
  hasRootAccess,
  isMaintenanceStaff
} from "../../util/Utils";
import { fetchMaintenanceDepartments } from "../../appRedux/actions/MaintenanceActions";

const SubMenu = Menu.SubMenu;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const dispatch = useDispatch();
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  const { authUser } = useSelector(({ auth }) => auth);
  let { pathname } = useSelector(({ common }) => common);
  const { maintenanceDepartments } = useSelector(state => state.maintenanceReducer);


  useEffect(() => {
    maintenanceDepartments.length === 0 && hasMaintenanceAdminAccess(authUser.role) && dispatch(fetchMaintenanceDepartments(true));
  }, [authUser.role, dispatch, maintenanceDepartments.length]);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys, !hasAllAccess(authUser.role) && "marketing-tools", "Maintenance"]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {/* tms module menu items */}
            {(hasAllAccess(authUser.role) || hasAdminAccess(authUser.role)) && (
              <>
                {hasAllAccess(authUser.role) ? (
                  <Menu.Item key="management">
                    <Link to="/management">
                      <i className="icon icon-dasbhoard" />
                      <span>
                        <IntlMessages id="sidebar.dashboard" />
                      </span>
                    </Link>
                  </Menu.Item>
                ) : (
                  <Menu.Item key="admin">
                    <Link to="/admin">
                      <i className="icon icon-dasbhoard" />
                      <span>
                        <IntlMessages id="sidebar.dashboard" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}

                <SubMenu
                  key="apartments"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      {" "}
                      <i className="icon icon-crm" />
                      <span>
                        <IntlMessages id="sidebar.buildingDetails" />
                      </span>
                    </span>
                  }
                >
                  {hasAllAccess(authUser.role) && (
                    <Menu.Item key="buildings">
                      <Link to="/buildings">
                        <i className="icon icon-company" />
                        <span>
                          <IntlMessages id="sidebar.buildings" />
                        </span>
                      </Link>
                    </Menu.Item>
                  )}
                  <Menu.Item key="apartment-list">
                    <Link to="/apartment-list">
                      <i className="icon icon-home" />
                      <span>
                        <IntlMessages id="sidebar.apartments" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tenants">
                    <Link to="/tenants">
                      <i className="icon icon-widgets" />
                      <span>
                        <IntlMessages id="sidebar.tenants" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="staffs">
                    <Link to="/staffs">
                      <i className="icon icon-widgets" />
                      <span>
                        <IntlMessages id="sidebar.staffs" />
                      </span>
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="retails">
                            <Link to="/apartment-list?type=retail">
                              <i className="icon icon-widgets" />
                              <span>
                                <IntlMessages id="sidebar.retails" />
                              </span>
                            </Link>
                          </Menu.Item> */}
                  <Menu.Item key="notice-board">
                    <Link to="/notice-board">
                      <i className="icon icon-notification" />
                      <span>
                        <IntlMessages id="sidebar.noticeBoard" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="welcome-pack">
                    <Link to="/welcome-pack">
                      <i className="icon icon-inbuilt-apps" />
                      <span>
                        <IntlMessages id="sidebar.welcomePack" />
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="tenants/blacklisted">
                  <Link to="/tenants/blacklisted">
                    <i className="icon icon-user" />
                    <span>
                      <IntlMessages id="sidebar.blackListedTenants" />
                    </span>
                  </Link>
                </Menu.Item>
                {hasAllAccess(authUser.role) && (
                  <Menu.Item key="users">
                    <Link to="/users">
                      <i className="icon icon-family" />
                      <span>
                        <IntlMessages id="sidebar.users" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="visitors">
                  <Link to="/visitors">
                    <i className="icon icon-family" />
                    <span>
                      <IntlMessages id="sidebar.visitors" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="vouchers">
                  <Link to="/vouchers">
                    <i className="icon icon-tag" />
                    <span>
                      <IntlMessages id="sidebar.vouchers" />
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="reports">
                  <Link to="/reports">
                    <i className="icon icon-tasks" />
                    <span>
                      <IntlMessages id="sidebar.reports" />
                    </span>
                  </Link>
                </Menu.Item>

                {/* <SubMenu
                    key="reports"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        {" "}
                        <i className="icon icon-tasks" />
                        <span>
                          <IntlMessages id="sidebar.reports" />
                        </span>
                      </span>
                    }
                  >
                      <Menu.Item key="reports">
                        <Link to="/reports">
                          <i className="icon icon-tasks" />
                          <span>
                            <IntlMessages id="sidebar.reports" />
                          </span>
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="tenant-reports">
                        <Link to="/tenant-reports">
                          <i className="icon icon-tasks" />
                          <span>
                            Tenant Reports
                          </span>
                        </Link>
                      </Menu.Item>

                </SubMenu> */}

                {/* services menu items access by only root user */}
                <SubMenu
                  key="App Services"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                    {" "}
                      <i className="icon icon-crm" />
                    <span>
                      <IntlMessages id="sidebar.app-services" />
                    </span>
                  </span>
                  }>
                  {hasRootAccess(authUser.role) && (
                    <Menu.Item key="services">
                      <Link to="/services">
                        <i className="icon icon-etherium" />
                        <span>
                          <IntlMessages id="sidebar.services" />
                        </span>
                      </Link>
                    </Menu.Item>
                  )}
                  <Menu.Item key="mobile-app-menu">
                    <Link to="/mobile-app-menu">
                      <i className="icon icon-setting" />
                      <span>
                          <IntlMessages id="sidebar.mobile-app-menu" />
                        </span>
                    </Link>
                  </Menu.Item>
                  {hasRootAccess(authUser.role) && (<Menu.Item key="tenant-service-settings">
                      <Link to="/tenant-service-settings">
                        <i className="icon icon-setting" />
                        <span>
                          <IntlMessages id="sidebar.tenant-service-settings" />
                        </span>
                      </Link>
                    </Menu.Item>
                  )}
                  {hasRootAccess(authUser.role) && (<Menu.Item key="building-service-settings">
                      <Link to="/building-service-settings">
                        <i className="icon icon-setting" />
                        <span>
                          <IntlMessages id="sidebar.building-service-settings" />
                        </span>
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>

                {hasAllAccess(authUser.role) && (
                  <SubMenu
                    key="settings"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        {" "}
                        <i className="icon icon-setting" />
                        <span>
                          <IntlMessages id="sidebar.settings" />
                        </span>
                      </span>
                    }
                  >
                    <Menu.Item key="payment-account">
                      <Link to="/payment-account">
                        <i className="icon icon-bitcoin" />
                        <span>
                          <IntlMessages id="sidebar.paymentAccount" />
                        </span>
                      </Link>
                    </Menu.Item>
                    {authUser.role === UserRole.ROOT && (
                      <Menu.Item key="general-settings">
                        <Link to="/general-settings">
                          <i className="icon icon-schedule" />
                          <span>
                            <IntlMessages id="sidebar.generalSettings" />
                          </span>
                        </Link>
                      </Menu.Item>
                    )}
                    {hasAllAccess(authUser.role) && (
                      <Menu.Item key="laundry">
                        <Link to="/laundry">
                          <i className="icon icon-etherium" />
                          <span>
                            <IntlMessages id="sidebar.laundry" />
                          </span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/* <Menu.Item key="sms-settings">
                                          <Link to="/sms-settings">
                                              <i className="icon icon-chat" />
                                              <span><IntlMessages id="sidebar.sms-settings" /></span>
                                          </Link>
                                      </Menu.Item> */}
                  </SubMenu>
                )}
              </>
            )}

            {/* marketing-module tools menu items */}
            {(hasMarketingAccess(authUser.role) ||
              hasAllAccess(authUser.role)) && (
              <SubMenu
                key="marketing-tools"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-crm" />
                    <span>
                      <IntlMessages id="sidebar.marketingTools" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="marketing">
                  <Link to="/marketing">
                    <i className="icon icon-etherium" />
                    <span>
                      <IntlMessages id="sidebar.marketing" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="advertisement-media">
                  <Link to="/advertisement-media">
                    <i className="icon icon-etherium" />
                    <span>
                      <IntlMessages id="sidebar.advertisement-media" />
                    </span>
                  </Link>
                </Menu.Item>
                {hasAllAccess(authUser.role) && (
                  <Menu.Item key="marketing-admin">
                    <Link to="/marketing-admin">
                      <i className="icon icon-etherium" />
                      <span>
                        <IntlMessages id="sidebar.marketingAdmin" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {/* maintenance-module menu items */}
            {(hasMaintenanceAccess(authUser.role) || hasRootAccess(authUser.role)) && (
              <SubMenu
                key="Maintenance"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                  {" "}
                    <i className="icon icon-crm" />
                  <span>
                    <IntlMessages id="sidebar.maintenance-module" />
                  </span>
                </span>
                }>
                  <Menu.Item key="maintenance">
                    <Link to="/maintenance">
                      <i className="icon icon-dasbhoard" />
                      <span>
                          <IntlMessages id="sidebar.maintenance" />
                        </span>
                    </Link>
                  </Menu.Item>
                  {
                    (!hasMaintenanceAdminAccess(authUser.role) && !hasRootAccess(authUser.role)) && (
                      <Menu.Item key="maintenance/tasks">
                        <Link to="/maintenance/tasks">
                          <i className="icon icon-etherium" />
                          <span><IntlMessages id="sidebar.maintenance-task" /></span>
                        </Link>
                      </Menu.Item>
                    )
                  }
                  {
                    (hasMaintenanceAdminAccess(authUser.role) || hasRootAccess(authUser.role)) && (
                      <SubMenu key="maintenance-tasks" 
                        popupClassName={getNavStyleSubMenuClass(navStyle)}
                        title={
                          <span>
                          {" "}
                            <i className="icon icon-crm" />
                          <span>
                            <IntlMessages id="sidebar.maintenance-task" />
                          </span>
                        </span>
                        }>
                        <Menu.Item key={`maintenance/tasks`}>
                            <Link to={`/maintenance/tasks`}>
                              <i className="icon icon-etherium" />
                              <span>{`All`}</span>
                            </Link>
                        </Menu.Item>
                        {
                          maintenanceDepartments.map(department => {
                            return (
                              <Menu.Item key={`maintenance/tasks/${department.id}`}>
                                <Link to={`/maintenance/tasks/${department.id}`}>
                                  <i className="icon icon-etherium" />
                                  <span>{`${department.name} (${department.faults_count?.PENDING?.count ?? 0})`}</span>
                                </Link>
                              </Menu.Item>
                            );
                          })
                        }
                      </SubMenu>
                    )
                  }
                  {
                    (hasMaintenanceAdminAccess(authUser.role) || hasRootAccess(authUser.role)) && (
                      <Menu.Item key="maintenance/departments">
                        <Link to="/maintenance/departments">
                          <i className="icon icon-etherium" />
                          <span><IntlMessages id="sidebar.maintenance-dept" /></span>
                        </Link>
                      </Menu.Item>
                    )
                  }
                  {
                    (!isMaintenanceStaff(authUser.role)) && (
                      <Menu.Item key="maintenance/staffs">
                        <Link to="/maintenance/staffs">
                          <i className="icon icon-etherium" />
                          <span><IntlMessages id="sidebar.maintenance-staff" /></span>
                        </Link>
                      </Menu.Item>
                    )
                  }
              </SubMenu>
            )}

            <Menu.Item key="logout">
              <Link to={"/signin"} onClick={() => dispatch(userSignOut())}>
                <LogoutOutlined />
                <span>
                  <IntlMessages id="sidebar.logout" />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
