import React, { useEffect, useState } from "react";
import { Alert, Button, Layout, Menu, Popover } from "antd";
import { Link, useHistory } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav, userSignOut } from "../../appRedux/actions";


import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import IntlMessages from "../../util/IntlMessages";
import { fetchPortalStatus } from "../../appRedux/actions/DashboardActions";
import {hasAdminAccess, hasAllAccess, hasMarketingAccess} from "../../util/Utils";

const { Header } = Layout;

const Topbar = () => {

    const { locale, navStyle } = useSelector(({ settings }) => settings);
    const { navCollapsed, width } = useSelector(({ common }) => common);
    const { authUser } = useSelector(({ auth }) => auth);
    const {portalStatus} = useSelector(state=> state.dashboardReducer);
    const dispatch = useDispatch();

    useEffect(()=>{
      hasAdminAccess(authUser.role) && dispatch(fetchPortalStatus());
    },[]);

    const handleVisitZKPortal = (portal) => {
        window.open(portal.portal_url+"/", "_blank")
    }

    const languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll">
            <ul className="gx-sub-popover">
                {languageData.map(language =>
                    <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() =>
                        dispatch(switchLanguage(language))
                    }>
                        <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
                        <span className="gx-language-text">{language.name}</span>
                    </li>
                )}
            </ul>
        </CustomScrollbars>);

    return (
        <Header>
            {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
                <div className="gx-linebar gx-mr-3">
                    <i className="gx-icon-btn icon icon-menu"
                        onClick={() => {
                            dispatch(toggleCollapsedSideNav(!navCollapsed));
                        }}
                    />
                </div> : null}

            {
                !hasMarketingAccess(authUser.role) && <Link to="/" className="gx-d-block gx-pointer">{
                    authUser?.assigned_building_names.map((building, index) => {
                        return <span key={index} className="gx-mr-2 gx-d-none gx-d-sm-block">{`${building} `}</span>
                    })}</Link>
            }

            { portalStatus !=  null && !hasAllAccess(authUser.role) && !hasMarketingAccess(authUser.role) &&
                <Alert
                    message={`${portalStatus[0].isReachable ? "ZK Server is reachable" : "ZK server is down."}`}
                    type={portalStatus[0].isReachable ? "success" : "error"}
                    showIcon
                    style={{ marginBottom: 0, marginLeft: 40}}
                />}

            <Link className="gx-header-notifications gx-ml-auto" to={'/signin'} onClick={() => dispatch(userSignOut())}>
                <LogoutOutlined /> <pre> </pre><span><IntlMessages id="sidebar.logout" /></span>
            </Link>

            {/* <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
                   trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                  <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
          </Popover>
        </li>
      </ul> */}
        </Header>
    );
};

export default Topbar;
