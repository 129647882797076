import {  RETAILS_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    retails: [],
};

const retailReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RETAILS_DATA: {
            return {
                ...state,
                retails: action.payload,
            }
        }
        default:
            return state;
    }
}

export default retailReducer;
