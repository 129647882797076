import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { UserRole } from "../enums/UserRole";
import Error401 from "../ui/errors/Error401";
import ProtectedRoute from "./ProtectedRoute";

const App = ({ match }) => {

    const routes = [
        {
            path: `${match.url}management`,
            component: asyncComponent(() => import('../ui/ManagementDashboard')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}admin`,
            component: asyncComponent(() => import('../ui/AdminDashboad')),
            allowedRoles: [UserRole.ADMIN]
        },
        {
            path: `${match.url}buildings`,
            component: asyncComponent(() => import('../ui/buildings/BuildingDetails')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}apartment-settings/:id`,
            component: asyncComponent(() => import('../ui/apartments/ApartmentSettings')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        {
            path: `${match.url}users`,
            component: asyncComponent(() => import('../ui/users/UserList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}reports`,
            component: asyncComponent(() => import('../ui/reports/Reports')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        {
            path: `${match.url}tenants`,
            component: asyncComponent(() => import('../ui/tenants/TenantList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        {
            path: `${match.url}staffs`,
            component: asyncComponent(() => import('../ui/staffs/StaffList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        // {
        //     path: `${match.url}retails`,
        //     component: asyncComponent(() => import('../ui/retails/RetailList')),
        //     allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        // },
        {
            path: `${match.url}apartment-list`,
            component: asyncComponent(() => import('../ui/apartments/ApartmentList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        // {
        //     path: `${match.url}apartment-list?type=retail`,
        //     component: asyncComponent(() => import('../ui/apartments/ApartmentList')),
        //     allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        // },
        {
            path: `${match.url}visitors`,
            component: asyncComponent(() => import('../ui/visitors/VisitorList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        {
            path: `${match.url}vouchers`,
            component: asyncComponent(() => import('../ui/vouchers/VoucherList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        {
            path: `${match.url}mobile-app-menu`,
            component: asyncComponent(() => import('../ui/app-services/mobile-menu/MobileAppSettings')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}tenant-service-settings`,
            component: asyncComponent(() => import('../ui/app-services/services/mappings/ServiceTenantList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}services`,
            component: asyncComponent(() => import('../ui/app-services/services/ServiceList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}building-service-settings`,
            component: asyncComponent(() => import('../ui/app-services/services/mappings/ServiceTypeBuildingList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}tenants/blacklisted`,
            component: asyncComponent(() => import('../ui/tenants/BlackListedTenantList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.ADMIN]
        },
        {
            path: `${match.url}payment-account`,
            component: asyncComponent(() => import('../ui/settings/paymentAccount/PaymentAccountSettings')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}general-settings`,
            component: asyncComponent(() => import('../ui/settings/GeneralSettings')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}laundry`,
            component: asyncComponent(() => import('../ui/settings/laundry/SiteMapping')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}notice-board`,
            component: asyncComponent(() => import('../ui/notice-board/NoticeBoard')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}welcome-pack`,
            component: asyncComponent(() => import('../ui/notice-board/NoticeBoard')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN]
        },
        {
            path: `${match.url}marketing`,
            component: asyncComponent(() => import('../ui/marketing-module/MarketingDashboard')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.MARKETING]
        },
        {
            path: `${match.url}advertisement-media`,
            component: asyncComponent(() => import('../ui/marketing-module/media/MediaList')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.MARKETING]
        },
        {
            path: `${match.url}marketing-admin`,
            component: asyncComponent(() => import('../ui/marketing-module/users/MarketingAdmin')),
            allowedRoles: [UserRole.ROOT,UserRole.SUPER_ADMIN,UserRole.MARKETING]
        },
        {
          path: `${match.url}maintenance`,
          component: asyncComponent(() => import('../ui/maintenance-module/MaintenanceDashboard')),
          allowedRoles: [UserRole.ROOT, UserRole.MAINTENANCE_ADMIN, UserRole.MAINTENANCE_DEPT_ADMIN, UserRole.MAINTENANCE_STAFF]
        },
        {
          path: `${match.url}maintenance/departments`,
          component: asyncComponent(() => import('../ui/maintenance-module/departments/DepartmentList')),
          allowedRoles: [UserRole.ROOT, UserRole.MAINTENANCE_ADMIN]
        },
        {
          path: `${match.url}maintenance/staffs`,
          component: asyncComponent(() => import('../ui/maintenance-module/staffs/StaffList')),
          allowedRoles: [UserRole.ROOT, UserRole.MAINTENANCE_ADMIN, UserRole.MAINTENANCE_DEPT_ADMIN]
        },
        {
          path: `${match.url}maintenance/tasks/:deptId`,
          component: asyncComponent(() => import('../ui/maintenance-module/tasks/TaskList')),
          allowedRoles: [UserRole.ROOT, UserRole.MAINTENANCE_ADMIN, UserRole.MAINTENANCE_DEPT_ADMIN, UserRole.MAINTENANCE_STAFF]
        },
        {
            path: `${match.url}maintenance/tasks`,
            component: asyncComponent(() => import('../ui/maintenance-module/tasks/TaskList')),
            allowedRoles: [UserRole.ROOT, UserRole.MAINTENANCE_ADMIN, UserRole.MAINTENANCE_DEPT_ADMIN, UserRole.MAINTENANCE_STAFF]
        },
    ];

    return (
        <div className="gx-main-content-wrapper">
            <Switch>
                <Route exact path='/unauthorized' component={Error401} />
                {
                    routes.map(route => <ProtectedRoute key={route.path} exact {...route}/>)
                }
            </Switch>
        </div>
    );
};

export default App;
