import { SERVICE_DATA, SERVICE_DATA_HAS_CODE, SERVICE_TYPE_BUILDING_MAP, SERVICE_TYPE_TENANT_TYPE_MAP } from "../../constants/ActionTypes";

const INIT_STATE = {
    services: [],
    servicesHasCode: [],
    serviceTenantTypeMap: [],
    serviceTypeBuildingMap: [],
};

const serviceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SERVICE_DATA: {
            return {
                ...state,
                services: action.payload,
            }
        }
        case SERVICE_TYPE_TENANT_TYPE_MAP: {
            return {
                ...state,
                serviceTenantTypeMap: action.payload,
            }
        }
        case SERVICE_TYPE_BUILDING_MAP: {
            return {
                ...state,
                serviceTypeBuildingMap: action.payload,
            }
        }
        case SERVICE_DATA_HAS_CODE: {
            return {
                ...state,
                servicesHasCode: action.payload,
            }
        }
        default:
            return state;
    }
}

export default serviceReducer;
