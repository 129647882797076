import { UserRole } from "../enums/UserRole";

export const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

export const hasAllAccess = (role) => {
  switch(role){
    case UserRole.ROOT:
      return true;
    case UserRole.SUPER_ADMIN:
      return true;
    default:
      return false;
  }
}

export const hasMarketingAccess = (role) => {
  switch(role){
    case UserRole.MARKETING:
      return true;
    default:
      return false;
  }
}

export const hasAdminAccess = (role) => {
  switch(role){
    case UserRole.ADMIN:
      return true;
    default:
      return false;
  }
}

export const hasMaintenanceAccess = (role) => {
  switch(role){
    case UserRole.MAINTENANCE_ADMIN:
    case UserRole.MAINTENANCE_DEPT_ADMIN:
    case UserRole.MAINTENANCE_STAFF:
      return true;
    default:
      return false;
  }
}
export const hasMaintenanceAdminAccess = (role) => {
  switch(role){
    case UserRole.MAINTENANCE_ADMIN:
      return true;
    default:
      return false;
  }
}
export const hasMaintenanceDeptAdminAccess = (role) => {
  switch(role){
    case UserRole.MAINTENANCE_DEPT_ADMIN:
      return true;
    default:
      return false;
  }
}

export const isMaintenanceStaff = (role) => {
  switch(role){
    case UserRole.MAINTENANCE_STAFF:
      return true;
    default:
      return false;
  }

}

export const hasModulePermission = (allowedModules, module) => {
  if(allowedModules?.includes(module)){
    return true;
  }
  return false;
}

export const hasRootAccess = (role) => {
  switch(role){
    case UserRole.ROOT:
      return true;
    default:
      return false;
  }
}

export const isBase64 = (data) => {
  if(data.includes("data:image/jpeg;base64")){
      return true;
  }
  return false;
}
