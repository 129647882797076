import { MARKETING_ADMINS_DATA, MARKETING_DASHBOARD_DATA, MARKETING_MEDIA_DATA } from "../../constants/ActionTypes";

const INIT_STATE = {
    buildingsWithMedia: [],
    mediaList: [],
    admins: [],
};

const marketingReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MARKETING_DASHBOARD_DATA: {
            return {
                ...state,
                buildingsWithMedia: action.payload,
            }
        }
        case MARKETING_MEDIA_DATA: {
            return {
                ...state,
                mediaList: action.payload,
            }
        }
        case MARKETING_ADMINS_DATA: {
            return {
                ...state,
                admins: action.payload,
            }
        }
        default:
            return state;
    }
}

export default marketingReducer;
